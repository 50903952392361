import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { IndexService } from '../../index/index.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: "app-faqpage",
  templateUrl: "faqpage.component.html"
})
export class FaqPageComponent implements OnInit, OnDestroy {
 
  constructor(private http: HttpClient, public indexservice: IndexService, private route: ActivatedRoute) {


  }
  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("landing-page");

  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("landing-page");
  }

 

}
