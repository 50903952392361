import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from '@angular/common/http';
@Component({
  selector: "app-successpage",
  templateUrl: "successpage.component.html"
})
export class SuccessPageComponent implements OnInit, OnDestroy {


  constructor(private http: HttpClient) {

  }
  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("landing-page");

 
   
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("landing-page");
  }

 
}
