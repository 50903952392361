import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { IndexService } from '../../index/index.service';
import { DatePipe } from '@angular/common';
import { IndexComponent } from '../../index/index.component'
import { ActivatedRoute } from '@angular/router';
import {loadStripe} from '@stripe/stripe-js';

@Component({
  selector: "app-pyramidbox",
  templateUrl: "pyramidbox.component.html"
})
export class PyramidboxComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  returnText = 'hi';
  isApproved = false;
  isError = false;
 
  CurrentTime;
  pipe = new DatePipe('en-US');
  now;
  boxParam;
  boxName;
  isClassic = false;

  isVerified: boolean;
  constructor(private http: HttpClient, public indexservice: IndexService, private route: ActivatedRoute) {
    this.isVerified = false;



  }
 

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("landing-page");
    this.makepayment();

   
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("landing-page");
  }

 
 
  checkTeamCode(code, game) {
    /// verify code
    this.indexservice.fireservice.collection('player keys').doc(code).valueChanges()
      .subscribe(result => {
       // console.log(result);
        if (result == undefined || result == null || result == "") {
          this.isApproved = false;
          this.isError = true;
        //  console.log('failed auth');
        }
        else if (result['exists'] == 'true') {
          this.isApproved = true;
          this.isError = false;

          var record = {}
   if (result['timeset'] == undefined ) {
        this.now = Date.now();  
       //  record['starttime'] = this.pipe.transform(this.now, 'dd-MM-yyyy HH:mm:ss');
       record['starttime'] = this.now;
         record['timeset'] = 'true';
         record['gamebox'] = 'classic';
        this.indexservice.fireservice.collection('player keys').doc(code).update(record);
   }

        } else {
          this.isApproved = false;
          this.isError = true;
        //  console.log('failed auth');
        }
      //  console.log(this.isApproved);


      }, error => {
        this.isApproved = false;
        this.isError = true;
      //  console.log('failed auth');
      })


  }

onLoadPaymentData =(event:Event):void =>{
  const eventDetails = event as CustomEvent<google.payments.api.PaymentData>;
  //console.log("load payment data",eventDetails.detail);
}

async makepayment(){
  var stripe = await loadStripe('pk_live_51IU7OjAHBIl8VhAelOGGeZbd6sO8hkj6dEG2ChPLD70n2CAqaH0F3jW3mdMNbVEY5PlQAfVAsGmyXNTTXoBdtD9e00lXV0NCoY');

var checkoutButton = document.getElementById('price_1IUukDAHBIl8VhAeW6mIL3si');
checkoutButton.addEventListener('click', function () {
  /*
   * When the customer clicks on the button, redirect
   * them to Checkout.
   */
  stripe.redirectToCheckout({
    lineItems: [{price: 'price_1IUuxkAHBIl8VhAemSwl8aSR', quantity: 1}],
    mode: 'payment',
    /*
     * Do not rely on the redirect to the successUrl for fulfilling
     * purchases, customers may not always reach the success_url after
     * a successful payment.
     * Instead use one of the strategies described in
     * https://stripe.com/docs/payments/checkout/fulfill-orders
     */
    successUrl: 'https://www.greymatterescaperoom.in/#/successfulpaymentrecieved',
    cancelUrl: 'https://www.greymatterescaperoom.in/#/cancelledpage',
  })
  .then(function (result) {
    if (result.error) {
      /*
       * If `redirectToCheckout` fails due to a browser or network
       * error, display the localized error message to your customer.
       */
      var displayError = document.getElementById('error-message');
      displayError.textContent = result.error.message;
    }
  });
});
}

}
